/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import "./Profile.scss";
import { supabase_helper } from "../../client";
import { useDispatch } from "react-redux";
import { univLogout } from "../../actions";
import { extractBranch, extractYear } from "../../functions/auth";
import { Button } from "@mui/material";

const Profile = () => {
	const dispatch = useDispatch();

	const [isProfilelocked, setIsProfilelocked] = useState(true);

	const [firstname, setFirstname] = useState("");
	const [surname, setSurname] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("--------");
	const [mobileno, setMobileno] = useState("");
	const [course, setCourse] = useState("");
	const [branch, setBranch] = useState("");
	const [year, setYear] = useState("");
	const [universityrollno, setUniversityrollno] = useState("");
	const [universitypassword, setUniversitypassword] = useState("");

	async function handlePageLoad() {
		const user = await supabase_helper.getUser();

		if (user) {
			const data = user.user_metadata;

			setFirstname(data.firstname);
			setSurname(data.surname);
			setMobileno(data.mobileno);
			setEmail(user.email);
			setUniversityrollno(data.universityrollno);
			setUniversitypassword(data.universitypassword);
			setCourse("B.Tech");
			setBranch(data.branch);
			setYear(data.year);
		}
	}

	function handleSaveprofile(e) {
		const updatedData = {
			email: email,
			data: {
				firstname: firstname,
				surname: surname,
				mobileno: mobileno,
				branch: branch,
				year: year,
				universityrollno: universityrollno,
				universitypassword: universitypassword,
			},
		};

		// console.log(updatedData);
		supabase_helper.update(updatedData);
		setIsProfilelocked(true);
		handlePageLoad();

		dispatch(univLogout());
	}

	function handleChange(e) {
		const value = e.target.value;

		switch (e.target.id) {
			case "firstname":
				return setFirstname(value);
			case "surname":
				return setSurname(value);
			case "email":
				return setEmail(value);
			case "password":
				setPassword(password)
				return alert("You cannot change your password now");
			case "mobileno":
				return setMobileno(value);
			case "universityrollno":
				setYear(extractYear(value));
				setBranch(extractBranch(value));
				return setUniversityrollno(value);
			case "universitypassword":
				return setUniversitypassword(value);
		}
	}

	function handleProfilestatus(e) {
		setIsProfilelocked(!isProfilelocked);
	}

	useEffect(() => {
		handlePageLoad();
	}, []);

	return (
		<div className="Profilecomponent fw-bold">
			<div className="container rounded bg-white w-100 mw-100 mx-auto mt-1 mb-3 mx-0 border border-primary">
				<div className="row">
					<div className="col-md-3 bg-info text-white">
						<div className="d-flex flex-column align-items-center text-center p-3 py-5">
							<img
								className="rounded-circle mt-3 shadow-lg px-2 py-0 mb-5 bg-white rounded"
								width="150px"
								src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
							/>
							<span className="fw-bold">{firstname + " " + surname}</span>
							<span className="text-white">{email}</span>
							<span className="mt-3">
								{(course.length ? course : "") +
									(branch.length ? " | " + branch : "") +
									(year.length ? " | " + year : "")}
							</span>
							<span className="text-black mt-2 mb-3 px-4 py-0 border rounded rounded-pill bg-white">
								{universityrollno}
							</span>
							<span> </span>
						</div>
					</div>
					<div className="row col gx-0 fw-bold">
						<div className="col-md-7 border">
							<div className="p-3 py-5 text-start">
								<div className="d-flex justify-content-between align-items-center mb-3">
									<h4 className="text-right">Profile</h4>

									{isProfilelocked ? (
										<i
											className="fs-2 fa fa-lock cursor-pointer"
											aria-hidden="true"
											onClick={handleProfilestatus}
											title="Click here to change your profile details"
										></i>
									) : (
										<i
											className="fs-2 fa fa-unlock cursor-pointer"
											aria-hidden="true"
											onClick={handleProfilestatus}
											title="Click here to save and lock your profile"
										></i>
									)}
								</div>
								<div className="row mt-2">
									<div className="col-md-6">
										<label className="labels">First Name</label>
										<input
											type="text"
											className="form-control"
											placeholder="first name"
											id="firstname"
											defaultValue={firstname}
											onChange={handleChange}
											maxLength={30}
											disabled={isProfilelocked}
										/>
									</div>
									<div className="col-md-6">
										<label className="labels">Last Name</label>
										<input
											type="text"
											className="form-control"
											placeholder="surname"
											id="surname"
											defaultValue={surname}
											onChange={handleChange}
											maxLength={50}
											disabled={isProfilelocked}
										/>
									</div>
								</div>
								<div className="row mt-3">
									<div className="col-md-6">
										<label className="labels">Email ID</label>
										<input
											type="text"
											className="form-control"
											placeholder="enter email id"
											id="email"
											defaultValue={email}
											onChange={handleChange}
											maxLength={50}
											required
											disabled={isProfilelocked}
										/>
									</div>
									<div className="col-md-6">
										<label className="labels">Password</label>
										<input
											type="password"
											className="form-control"
											placeholder="password (not university one)"
											id="password"
											defaultValue={password}
											onChange={handleChange}
											maxLength={15}
											required
											disabled={true}
										/>
									</div>
									<div className="col-md-12 mt-2">
										<label className="labels">Mobile Number</label>
										<input
											type="text"
											className="form-control"
											placeholder="enter mobile number"
											id="mobileno"
											defaultValue={mobileno}
											onChange={handleChange}
											maxLength={10}
											required
											disabled={isProfilelocked}
										/>
									</div>
								</div>
							</div>

							<div className="text-end mt-3 p-3">
								<Button
									variant={isProfilelocked ? "outlined" : "contained"}
									aria-label="Click to Edit Profile"
									onClick={
										!isProfilelocked ? handleSaveprofile : handleProfilestatus
									}
								>
									{isProfilelocked ? "Edit" : "Save"}
								</Button>
							</div>
						</div>
						<div className="col-md-5 bg-info text-white">
							<div className="p-3 py-5 text-start">
								<div className="d-flex justify-content-between align-items-center experience">
									<span className="border px-3 p-1 add-experience mx-auto">
										<i className="fa fa-building" aria-hidden="true"></i>
										&nbsp;University Details
									</span>
								</div>
								<br />
								<div className="col-md-12">
									<label className="labels">Roll No</label>
									<input
										type="text"
										className="form-control"
										placeholder={"University Roll No. (2KXX/AA/111)"}
										id="universityrollno"
										defaultValue={universityrollno}
										onChange={handleChange}
										autoComplete="off"
										maxLength={15}
										required
										disabled={isProfilelocked}
									/>
								</div>
								<br />
								<div className="col-md-12">
									<label className="labels">Password</label>
									<input
										type="password"
										className="form-control"
										placeholder="Password (university password)"
										id="universitypassword"
										defaultValue={universitypassword}
										onChange={handleChange}
										autoComplete="off"
										maxLength={50}
										required
										disabled={isProfilelocked}
									/>
								</div>

								<div className="row mt-3">
									<div className="col-md-6">
										<label className="labels">Course</label>
										<input
											type="text"
											className="form-control"
											placeholder="course (B.Tech)"
											id="course"
											defaultValue={course}
											// onChange={handleChange}
											maxLength={15}
											required
											disabled={true}
										/>
									</div>
									<div className="col-md-6">
										<label className="labels">Admission Year</label>
										<input
											type="text"
											className="form-control"
											placeholder="year"
											id="year"
											defaultValue={year}
											// onChange={handleChange}
											maxLength={4}
											minLength={4}
											required
											disabled={true}
										/>
									</div>
									<div className="col-md-12">
										<label className="labels">Branch</label>
										<input
											type="select"
											className="form-control"
											placeholder="branch (EE, ME etc.)"
											id="branch"
											defaultValue={branch}
											// onChange={handleChange}
											maxLength={30}
											required
											disabled={true}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Profile;
