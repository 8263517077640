import Notiflix from "notiflix";
import { supabase_helper } from "../client";

// App Login Request to Supabase Server
export const appLoginServerRequest = async (data, callback) => {
	const credentials = {
		email: data.email,
		password: data.password,
	};

	const res = await supabase_helper.signIn(credentials);

	if (res.status === 400) {
		// alert('Invalid Login Credentials')
		// Notiflix.Notify.failure(res.message);
		return callback(res, null);
	}

	if (res.role !== "authenticated") return callback(res, null);

	return callback(null, res);
};

// Signup Form Validation
function validateSignupFormData(formdata) {
	var invalidData = "";

	const regexemail = /\S+@\S+\.\S+/gi;
	if (!regexemail.test(formdata.email.toLowerCase())) {
		invalidData += "⚠ Invalid email address\n";
	}

	if (
		formdata.password.toString().length < 6 ||
		formdata.password.toString().length > 15
	) {
		invalidData += "⚠ Invalid password (6-15 characters)\n\n";
	}

	if (
		formdata.data.firstname.toString().length < 3 ||
		formdata.data.firstname.toString().length > 30
	) {
		invalidData += "⚠ Invalid firstname (3-15 characters)\n\n";
	}

	if (
		formdata.data.surname.toString().length < 3 ||
		formdata.data.surname.toString().length > 30
	) {
		invalidData += "⚠ Invalid surname (3-30 characters)\n\n";
	}

	if (
		formdata.data.mobileno.toString().length < 10 ||
		formdata.data.firstname.toString().length > 15
	) {
		invalidData += "⚠ Invalid mobileno (min 10-15 characters)\n\n";
	}

	if (
		formdata.data.branch.toString().length < 2 ||
		formdata.data.branch.toString().length > 40
	) {
		invalidData += "⚠ Invalid branch (min 2-40 characters)\n\n";
	}

	if (
		formdata.data.year.toString().length < 4 ||
		formdata.data.year.toString().length > 4
	) {
		invalidData += "⚠ Invalid graduation year (4 characters format - XXXX)\n\n";
	}

	const regexuniversityrollno =
		/(2[kK]{1}[\d]{2}\/)([a-zA-Z]{2,3}\/)([\d]{1,3})\b/gi;
	const isValidRollno = regexuniversityrollno.test(
		formdata.data.universityrollno.toString()
	);

	if (!isValidRollno) {
		invalidData +=
			"⚠ Invalid university roll no (e.g. format - 2k18/aa/111)\n\n";
	}

	if (formdata.data.universitypassword.toString().length < 2) {
		invalidData += "⚠ Invalid university login password (min 2 characters)\n\n";
	}

	return invalidData;
}

// Extract "Branch" in Upper Case from "universityrollno"
export const extractBranch = (universityrollno) => {
	let branch = universityrollno.substring(universityrollno.indexOf("/") + 1);
	branch = branch.substring(0, branch.indexOf("/"));
	return branch.toUpperCase();
};

// Extract "Year" in Number/String (2K20 format) from "universityrollno"
export const extractYear = (universityrollno) => {
	let year = universityrollno
		.substring(0, universityrollno.indexOf("/"))
		.toUpperCase();
	return year;
};

// App Signup Request to Supabase Server
export const appSignupServerRequest = async (data, callback) => {
	data.branch = extractBranch(data.universityrollno);
	data.year = extractYear(data.universityrollno);

	const credentials = {
		email: data.email || "",
		password: data.password || "",
		data: {
			firstname: data.firstname || "",
			surname: data.surname || "",
			mobileno: data.mobileno || "",
			branch: data.branch || "",
			year: data.year || "",
			universityrollno: data.universityrollno || "",
			universitypassword: data.universitypassword || "",
		},
	};

	const validationMessage = validateSignupFormData(credentials);
	// console.log(validationMessage)

	if (validationMessage && validationMessage.length > 0) {
		// return alert(validationMessage);
		return Notiflix.Notify.failure(validationMessage);
	}

	const res = await supabase_helper.signUp(credentials);
	// console.log(res)

	if (res.status === 400) {
		// const _message = "Email already registered.\n\nPlease signup with different email address."
		// alert(
		// 	"Email already registered.\n\nPlease signup with different email address."
		// );

		// Notiflix.Notify.failure(error.message);
		return callback(res, null);
	}

	if (res.identities && res.identities.length === 0) {
		// alert(
		// 	"Email already registered.\n\nPlease signup with different email address."
		// );
		return callback(res, null);
	}

	if (res.role !== "authenticated") return callback(res, null);

	return callback(null, res);
};
