import "./App.scss";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Loginpage from "./pages/Login/Loginpage.jsx";
import Homepage from "./pages/Home/Homepage.jsx";
import { refreshMessageBox, routeList, switchSection } from "./actions";
import { Button, Snackbar } from "@mui/material";

function App() {
	const dispatch = useDispatch();
	const isLogged = useSelector((state) => state.isLogged);
	const snackbarProps = useSelector((state) => state.snackbarReducer);

	return (
		<div className="App">
			<Snackbar
				open={snackbarProps.open}
				onClose={() =>
					dispatch(
						refreshMessageBox({
							type: "CLOSE",
							msg: "",
						})
					)
				}
				message={snackbarProps.msg}
			/>

			{isLogged ? (
				<>
					<div className="d-flex mt-2 text-white justify-content-between mb-3">
						<h1 className="text-start logo" id="logo">
							REGNOW
						</h1>

						<div className="text-end align-items-center align-content-center navbar-actions py-auto mb-1">
							{routeList["navbar"].map((element) => {
								return (
									<Button
										key={element.name + "btn"}
										variant="contained"
										onClick={() => dispatch(switchSection(element.route))}
										sx={{ ml: 1 }}
									>
										{element.icon} {element.name}
									</Button>
								);
							})}
						</div>
					</div>
				</>
			) : (
				<></>
			)}

			<Router>
				<div className="AppAfterTopbar">
					<Routes>
						<Route path="/" element={<Homepage />} />
						<Route path="/login" element={<Loginpage />} />
					</Routes>
				</div>
			</Router>
		</div>
	);
}

export default App;
