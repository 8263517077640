/* eslint-disable array-callback-return */
import axios from "axios";
import jquery from "jquery";
import Notiflix, { Confirm, Loading } from "notiflix";
import qs from "qs";
import { supabase_helper } from "../client";
import { ALL_CONSTANTS } from "../constants";

export async function loginServerRequest(data, callback) {
	Loading.standard("Loading...", {
		backgroundColor: "rgba(0,0,0,0.8)",
	});

	supabase_helper.getUser().then((user) => {
		// console.log(user);

		supabase_helper
			.db_get_courselist({
				year_branch: user.user_metadata.year + "-" + user.user_metadata.branch,
			})
			.then((courses) => {
				// console.log("courses", courses);
				// loginUniversityServerRequest(user, callback)

				if (courses && courses.length > 0) {
					const courseList = courses[0]?.courselist;
					// console.log(courseList);

					loginUniversityServerRequest(user, "headers", callback);

					callback({
						data: {
							// headers: {},
							courseList: {
								electiveCoursesList: courseList || [],
							},
						},
					});

					// console.log("this is after callback");
				} else if (courses && courses.length === 0) {
					loginUniversityServerRequest(user, "courses", callback);
				}

				Loading.remove();
			});
	});
}

export function loginUniversityServerRequest(user, param, callback) {
	const reqbody = {
		username: user?.user_metadata?.universityrollno,
		password: user?.user_metadata?.universitypassword,
	};

	var url =
		process.env.REACT_APP_NODE_ENV === "DEVELOPMENT"
			? process.env.REACT_APP_DEV_BASE_URL
			: "";

	// url = "https://course-erp-server.herokuapp.com/";
	url = url.concat(`api/course/all`);

	// console.log(url);

	const options = {
		method: "POST",
		url: url,
		data: qs.stringify(reqbody),
		headers: {
			"content-type": "application/x-www-form-urlencoded;charset=utf-8",
			"Access-Control-Allow-Origin": "*",
		},
	};

	axios(options)
		.then((response) => {
			if (response?.data?.headers) {
				localStorage.setItem("headers", JSON.stringify(response.data.headers));
			} else {
				loginUniversityServerRequest(user, "headers", callback);
			}

			if (param === "dynamicheaders") {
				callback(response);
				return;
			}

			if (param === "courses") {
				if (response?.data?.courseList) {
					const courselist_to_insert = {
						year: user?.user_metadata?.year,
						branch: user?.user_metadata?.branch,
						year_branch:
							user?.user_metadata?.year + "-" + user?.user_metadata?.branch,
						courselist: response?.data?.courseList?.electiveCoursesList,
					};

					supabase_helper.db_insert_courselist(courselist_to_insert);
				} else {
					loginUniversityServerRequest(user, "courses", callback);
				}
			}

			if (response?.data?.headers && response?.data?.courseList) {
				callback(response);
			}
		})
		.catch((error, res) => {
			console.log(error, res);
			// alert("Error");
		});
}

export function readCourses(slot, view) {
	const slotWiseCourseList = localStorage.getItem("slotWiseCourseList")
		? JSON.parse(localStorage.getItem("slotWiseCourseList"))[slot]
		: [];

	let finalCourses = [];

	if (view === "multiselect") {
		slotWiseCourseList.map((course) => {
			const temparr = course.courseCode + " " + course.courseName;
			finalCourses.push(temparr);
		});

		return finalCourses;
	}

	return slotWiseCourseList;
}

export function registerCourseRequest(data, callback) {
	const selectedCoursesList = JSON.parse(
		localStorage.getItem("selectedCoursesList")
	);

	// if (
	// 	!window.confirm(
	// 		"Click OK to give us permission to register the selected courses for your roll number\n\n\nClick cancel to stop course registration process"
	// 	)
	// )
	// 	return;

	console.log(selectedCoursesList);

	if (selectedCoursesList)
		Object.keys(selectedCoursesList).map((key) => {
			selectedCoursesList[key].map((course, index) => {
				const coursereqdata = {};

				coursereqdata.instId = 1;
				coursereqdata.stuId = "";
				coursereqdata.subjId = course.courseId;
				coursereqdata.sxnId = 0;
				coursereqdata.esetId = course.esetId;
				coursereqdata.facultyName = undefined;

				const courserequestInterval = setInterval(() => {
					registerCourseServerRequest(
						coursereqdata,
						registerCourseServerRequestCallback
					);
				}, 1000);

				const courserequestIntervalCollection = localStorage.getItem(
					"courserequestinterval"
				)
					? JSON.parse(localStorage.getItem("courserequestinterval"))
					: [];
				courserequestIntervalCollection.push(courserequestInterval);

				// console.log(courserequestInterval, courserequestIntervalCollection)

				localStorage.setItem(
					"courserequestinterval",
					JSON.stringify(courserequestIntervalCollection)
				);
			});
		});
}

const registerCourseServerRequestCallback = (payload) => {
	// console.log(payload);

	const { data = {} } = payload;
	if (data.error === true) {
		if (data.msgLst && data.msgLst.length) {
			if (
				ALL_CONSTANTS[data.msgLst[0].errCode] === "REGISTRATION_NOT_PERMISSIBLE"
			) {
				stopCourseRegistration(ALL_CONSTANTS["REGISTRATION_NOT_PERMISSIBLE"]);
				Notiflix.Notify.warning("REGISTRATION NOT PERMISSIBLE", {
					timeout: 6000,
					position: "right-bottom",
				});
			}
		}
	}
};

export const stopCourseRegistration = (msg) => {
	const allIntervals = localStorage.getItem("courserequestinterval")
		? JSON.parse(localStorage.getItem("courserequestinterval"))
		: [];
	allIntervals.map((interval) => {
		clearInterval(interval);
	});

	const callsnackbarprops = {
		type: "OPEN",
		msg: "Stopped Auto-Registration !!! \n" + (msg ? msg : ""),
	};

	console.log(callsnackbarprops.msg);
	Notiflix.Notify.info(callsnackbarprops.msg, {
		timeout: 6000,
		position: "right-bottom",
		messageMaxLength: 110,
	});

	// alert(callsnackbarprops.msg);
	localStorage.removeItem("courserequestinterval");
};

export function processCourseListwithSession(selectedList, slotnow) {
	var currentSelectedCourseListFromSession = localStorage.getItem(
		"selectedCoursesList"
	)
		? JSON.parse(localStorage.getItem("selectedCoursesList"))
		: {};

	if (localStorage.getItem("selectedCoursesList")) {
		// console.log("if");
		currentSelectedCourseListFromSession[slotnow] = selectedList;
		console.log("if", currentSelectedCourseListFromSession, selectedList);
	} else {
		// currentSelectedCourseListFromSession = [];
		currentSelectedCourseListFromSession[slotnow] = selectedList;
		console.log("else", currentSelectedCourseListFromSession, selectedList);
	}

	localStorage.setItem(
		"selectedCoursesList",
		JSON.stringify(currentSelectedCourseListFromSession)
	);

	// console.log(JSON.parse(localStorage.getItem('selectedCoursesList')))

	jquery(jquery("#flush-collapseSelectedCourses")[0]).addClass("show");
	jquery(jquery("#flush-collapseRegisteredCourses")[0]).removeClass("show");
}

export function readSelectedCourseValuesMultiselect(slot) {
	var currentCourseListFromSession = localStorage.getItem(
		"selectedCoursesList"
	);
	if (currentCourseListFromSession) {
		currentCourseListFromSession = JSON.parse(currentCourseListFromSession);
		return currentCourseListFromSession[slot] || [];
	} else {
		return [];
	}
}

export function registerCourseServerRequest(data, callback) {
	// console.log(data);

	// subId        -> courseId
	// esetId       -> esetId
	// instId       -> 1 (default)
	// sxnId        -> 0 (default)
	// facultyName  -> undefined (default)

	// console.log("registering");

	const BASE_URL = process.env.REACT_APP_COURSE_REGISTER_BASE_URL;
	const url = `${BASE_URL}instId=${data.instId}&stuId=${data.stuId}&subjId=${data.subjId}&sxnId=${data.sxnId}&esetId=${data.esetId}&facultyName=${data.facultyName}`;

	// console.log(url);

	var headers = JSON.parse(localStorage.getItem("headers")) || {};

	if (localStorage.getItem("headers")) {
		headers = JSON.parse(localStorage.getItem("headers"));
		const options = {
			method: "POST",
			url: url,
			data: qs.stringify(data),
			headers: headers,
		};

		// console.log(options);

		// if (process.env.REACT_APP_NODE_ENV === "PRODUCTION") {
		axios(options)
			.then((response) => {
				callback(response);
			})
			.catch((error, res) => {
				console.log(error, res);
			});
		// }
	} else {
		supabase_helper.getUser().then((user) => {
			// console.log(user);
			loginUniversityServerRequest(user, "dynamicheaders", () => {
				registerCourseServerRequest(data, callback);
			});
		});
	}
}

export function toTitleCase(str) {
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
}

export function processCourseSelectionwithSession(
	selectedCourse,
	slotnow,
	opn,
	callback
) {
	// console.log(selectedCourse, slotnow, opn)
	var currentSelectedCourseListFromSession = localStorage.getItem(
		"selectedCoursesList"
	)
		? JSON.parse(localStorage.getItem("selectedCoursesList"))
		: {};

	const currentSlotCourseList =
		currentSelectedCourseListFromSession[slotnow] || [];

	if (opn === "add")
		currentSelectedCourseListFromSession[slotnow] = [
			...currentSlotCourseList,
			selectedCourse,
		];
	else if (opn === "remove") {
		currentSelectedCourseListFromSession[slotnow] =
			currentSlotCourseList.filter(
				(obj) => obj.courseId !== selectedCourse.courseId
			);
		jquery(
			`.course-code-name-wrapper[data-courseid=${selectedCourse.courseId}]`
		).removeClass("selected-course");
	}

	currentSelectedCourseListFromSession[slotnow] = [
		...new Set(currentSelectedCourseListFromSession[slotnow]),
	];

	localStorage.setItem(
		"selectedCoursesList",
		JSON.stringify(currentSelectedCourseListFromSession)
	);

	jquery(jquery("#flush-collapseSelectedCourses")[0]).addClass("show");
	jquery(jquery("#flush-collapseRegisteredCourses")[0]).removeClass("show");

	callback(currentSelectedCourseListFromSession);
}

export const getIsCourseSelected = (selectedCourse, slotnow) => {
	var currentSelectedCourseListFromSession = localStorage.getItem(
		"selectedCoursesList"
	)
		? JSON.parse(localStorage.getItem("selectedCoursesList"))
		: {};

	let currentSlotCourseList =
		currentSelectedCourseListFromSession[slotnow] || [];

	currentSlotCourseList = currentSlotCourseList.filter(
		(obj) => obj.courseId === selectedCourse.courseId
	);
	currentSlotCourseList = [...new Set(currentSlotCourseList)];

	return currentSlotCourseList.length ? true : false;
};
