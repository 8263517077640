const selectedcourseReducer = (state = true, action) => {
	// console.log(state, action)
	switch (action.type) {
		case "SHOWSELECTEDCOURSELIST":
			return true;
		case "HIDESELECTEDCOURSELIST":
			return false;
		default:
			return state;
	}
};

export default selectedcourseReducer;

export const selectedCourselistReducer = (
	state = localStorage.getItem("selectedCoursesList")
		? JSON.parse(localStorage.getItem("selectedCoursesList"))
		: {},
	action
) => {
	// console.log(state, action, action.data)
	switch (action.type) {
		case "SHOWLIST":
			return action.data;
		default:
			return state;
	}
};

export const snackbarState = (state = { open: true, msg: "" }, action) => {
	// console.log(state, action)
	switch (action.type) {
		case "OPEN":
			return {
				...state,
				open: true,
				msg: action.data,
			};
		default:
			return {
				...state,
				open: false,
				msg: "",
			};
	}
};
