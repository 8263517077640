import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	appLoginServerRequest,
	appSignupServerRequest,
} from "../../functions/auth";
import "./Login.scss";

import { login, logout, showProfilesection, univLogout } from "../../actions";
import { Navigate } from "react-router-dom";
import { supabase_helper } from "../../client";
import { Loading } from "notiflix";

export default function Login() {
	const dispatch = useDispatch();
	const isLogged = useSelector((state) => state.isLogged);

	async function handlePageload() {
		// console.log("Handling page load : ", isLogged);
		if (!isLogged)
			supabase_helper.session().then((res) => {
				// console.log(res);
				if (res) {
					dispatch(univLogout());
					dispatch(showProfilesection());
					return dispatch(login());
				} else {
					localStorage.clear();
				}
			});
	}

	function handleSignin(e) {
		const formData = {
			email: document.getElementById("logemail").value,
			password: document.getElementById("logpass").value,
		};

		// console.log(formData);

		Loading.hourglass();

		appLoginServerRequest(formData, (err, res) => {
			Loading.remove();
			if (err) {
				// console.log(err);
				dispatch(logout());
			} else {
				// console.log(res);
				localStorage.setItem("loggeduser", JSON.stringify(res));

				dispatch(univLogout());
				dispatch(showProfilesection());

				dispatch(login());
			}
		});
	}

	function handleSignup() {
		Loading.hourglass();

		const formData = {
			firstname: document.getElementById("regfirstname").value,
			surname: document.getElementById("regsurname").value,
			mobileno: document.getElementById("regmobileno").value,
			email: document.getElementById("regemail").value,
			password: document.getElementById("regpassword").value,
			// branch: document.getElementById("regbranch").value,
			// year: document.getElementById("regyear").value,
			universityrollno: document.getElementById("reguniversityrollno").value,
			universitypassword: document.getElementById("reguniversitypassword")
				.value,
		};

		// console.log(formData);

		appSignupServerRequest(formData, (err, res) => {
			Loading.remove();

			if (err) {
				dispatch(logout());
			} else {
				// console.log(res);
				localStorage.setItem("loggeduser", JSON.stringify(res));
				dispatch(login());
			}
		});
	}

	if (isLogged) {
		return <Navigate to="/" />;
	}

	handlePageload();

	return (
		<div className="LoginComponent">
			<div className="section text-white">
				<div className="container">
					<div className="row full-height justify-content-center">
						<div className="col-12 text-center align-self-center py-2">
							<div className="section pb-5 pt-2 pt-sm-2 text-center">
								<h6 className="mb-0 pb-3 text-white">
									<span>Login </span>
									<span>Register</span>
								</h6>
								<input
									className="checkbox"
									type="checkbox"
									id="reg-log"
									name="reg-log"
								/>
								<label htmlFor="reg-log"></label>
								<div className="card-3d-wrap mx-auto">
									<div className="card-3d-wrapper">
										<div className="card-front">
											<div className="center-wrap">
												<div className="section text-center">
													<h3 className="fw-bold mb-4 pb-3 px-3 py-2 bg-warning text-black rounded-pill">
														regnow
													</h3>
													<h4 className="mb-4 pb-3">Log In</h4>
													<div className="form-group">
														<input
															type="email"
															name="logemail"
															className="form-style"
															placeholder="Your Email"
															id="logemail"
															autoComplete="off"
														/>
														<i className="input-icon uil uil-at">&#64;</i>
													</div>
													<div className="form-group mt-2">
														<input
															type="password"
															name="logpass"
															className="form-style"
															placeholder="Your Password"
															id="logpass"
															autoComplete="off"
														/>
														<i
															className="input-icon fa fa-lock"
															aria-hidden="true"
														></i>
													</div>
													<button
														href="#"
														className="btn mt-4"
														onClick={handleSignin}
													>
														Login
													</button>
												</div>
											</div>
										</div>
										<div className="card-back">
											<div className="center-wrap">
												<div className="section text-center">
													<h4 className="mb-2 pb-2">Sign Up</h4>

													<div className="row gx-2">
														<div className="col-md-6 mt-1">
															<div className="form-group">
																<input
																	type="text"
																	name="regfirstname"
																	className="form-style"
																	placeholder="Your First Name"
																	id="regfirstname"
																	autoComplete="off"
																/>
																<i
																	className="input-icon fa fa-user-circle"
																	aria-hidden="true"
																></i>
															</div>
														</div>
														<div className="col-md-6 mt-1">
															<div className="form-group">
																<input
																	type="text"
																	name="regsurname"
																	className="form-style"
																	placeholder="Your Surname"
																	id="regsurname"
																	autoComplete="off"
																/>
																<i
																	className="input-icon fa fa-user-circle"
																	aria-hidden="true"
																></i>
															</div>
														</div>
													</div>

													<div className="form-group mt-2">
														<input
															type="text"
															name="regmobileno"
															className="form-style"
															placeholder="Your Mobile Number"
															id="regmobileno"
															autoComplete="off"
															maxLength={10}
														/>
														<i
															className="input-icon ms-1 fa fa-phone"
															aria-hidden="true"
														></i>
													</div>

													<div className="form-group mt-2">
														<input
															type="email"
															name="regemail"
															className="form-style"
															placeholder="Your Email"
															id="regemail"
															autoComplete="off"
														/>
														<i className="input-icon uil uil-at">&#64;</i>
													</div>
													<div className="form-group mt-2">
														<input
															type="password"
															name="regpassword"
															className="form-style"
															placeholder="Your Password"
															id="regpassword"
															autoComplete="off"
														/>
														<i className="input-icon fa fa-lock"></i>
													</div>

													<h5 className="my-3">University Details</h5>

													<div className="form-group mt-2">
														<input
															type="text"
															name="reguniversityrollno"
															className="form-style"
															placeholder="Roll No (University)"
															id="reguniversityrollno"
															autoComplete="off"
														/>
														<i
															className="input-icon fa fa-hashtag"
															aria-hidden="true"
														></i>
													</div>

													<div className="form-group mt-2">
														<input
															type="password"
															name="reguniversitypassword"
															className="form-style"
															placeholder="Password (University)"
															id="reguniversitypassword"
															autoComplete="off"
														/>
														<i className="input-icon fa fa-lock"></i>
													</div>

													<button
														href="#"
														className="btn mt-4"
														onClick={handleSignup}
													>
														Register
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
