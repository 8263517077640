import {
	AccountCircle,
	AppRegistrationOutlined,
	ExitToApp,
	ViewList,
} from "@mui/icons-material";
import { supabase_helper } from "../client";

export const login = () => {
	return {
		type: "LOGIN",
	};
};

export const logout = () => {
	supabase_helper.signOut();
	univLogout();
	localStorage.clear();

	return {
		type: "LOGOUT",
	};
};

// -------------------------------------------

export const univLogin = () => {
	return {
		type: "UNIVLOGIN",
	};
};

export const univLogout = () => {
	return {
		type: "UNIVLOGOUT",
	};
};

// -------------------------------------------

export const switchSection = (section) => {
	if (section === "LOGOUT") {
		return logout();
	}

	return {
		type: section.toUpperCase(),
	};
};

export const showHomesection = () => {
	return {
		type: "HOMESECTION",
	};
};

export const showProfilesection = () => {
	return {
		type: "PROFILESECTION",
	};
};

export const showCourselistsection = () => {
	return {
		type: "COURSELISTSECTION",
	};
};

export const showCourserequestsection = () => {
	return {
		type: "COURSEREQUESTSECTION",
	};
};

// -------------------------------------------

export const showSelectedcourselist = () => {
	return {
		type: "SHOWSELECTEDCOURSELIST",
	};
};

export const hideSelectedcourselist = () => {
	return {
		type: "HIDESELECTEDCOURSELIST",
	};
};

export const refreshSelectedcourselist = (data) => {
	return {
		type: "SHOWLIST",
		data: data,
	};
};

export const refreshMessageBox = (data) => {
	return {
		type: data.type,
		data: data.msg,
	};
};

export const routeList = {
	navbar: [
		{
			name: "Profile",
			route: "PROFILESECTION",
			icon: <AccountCircle />,
		},
		{
			name: "List",
			route: "COURSELISTSECTION",
			icon: <ViewList />,
		},
		{
			name: "Register",
			route: "COURSEREQUESTSECTION",
			icon: <AppRegistrationOutlined />,
		},
		{
			name: "Logout",
			route: "LOGOUT",
			icon: <ExitToApp />,
		},
	],
};
