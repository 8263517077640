import { createClient } from "@supabase/supabase-js";
import Notiflix from "notiflix";

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL || "";
const SUPABASE_ANON_KEY = process.env.REACT_APP_SUPABASE_ANON_KEY || "";

const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);

async function supabase_signIn(body) {
	let { user, error } = await supabase.auth.signIn({
		email: body.email,
		password: body.password,
	});

	if (error) {
		// console.log(error);
		Notiflix.Notify.failure(error.message);
		return error;
	}

	console.log(user);
	return user;
}

async function supabase_signUp(body) {
	let { user, error } = await supabase.auth.signUp(
		{
			email: body.email,
			password: body.password,
		},
		{
			data: body.data,
		}
	);

	if (error) {
		// console.log(error);
		Notiflix.Notify.failure(error.message);
		return error;
	}

	// console.log(user);
	return user;
}

async function supabase_getUser() {
	const user = supabase.auth.user();
	return user;
}

async function supabase_signOut(body) {
	const { error } = await supabase.auth.signOut();

	if (error) {
		// console.log(error);
		Notiflix.Notify.failure(error.message);
		return error;
	}

	return {};
}

async function supabase_session() {
	const session = await supabase.auth.session();
	// console.log(session);
	return session;
}

async function supabase_update(body) {
	// console.log(body)
	const { user, error } = await supabase.auth.update(body);

	if (error) {
		// console.log('error', error)
		// alert("Error while saving data");
		Notiflix.Notify.failure(error.message);
		return error;
	}

	// console.log(user)
	return user;
}

async function supabase_db_get_courselist(q) {
	let { data: courselist, error } = await supabase
		.from("course_list")
		.select("*")
		.match({
			year_branch: q.year_branch,
		});

	if (error) {
		// return alert("DB ERROR : " + error);
		Notiflix.Notify.failure(error.message);
		return;
	}

	// console.log(courselist);
	return courselist;
}

async function supabase_db_insert_courselist(querydata) {
	const { data, error } = await supabase
		.from("course_list")
		.insert([querydata]);

	if (error) {
		// return alert("DB ERROR : " + error);
		Notiflix.Notify.failure(error.message);
		return;
	}

	// console.log(data);
	return data;
}

export const supabase_helper = {
	supabase: supabase,
	signIn: supabase_signIn,
	signUp: supabase_signUp,
	getUser: supabase_getUser,
	signOut: supabase_signOut,
	session: supabase_session,
	update: supabase_update,
	db_get_courselist: supabase_db_get_courselist,
	db_insert_courselist: supabase_db_insert_courselist,
};
