import React from "react";
import { useSelector } from "react-redux";

import "./Courserequest.scss";

import Selectedcourselist from "../Selectedcourselist/Selectedcourselist";
import {
	registerCourseRequest,
	stopCourseRegistration,
} from "../../functions/course";
import { Confirm, Notify } from "notiflix";

export default function Courselist() {
	const isAppLogged = useSelector((state) => state.isAppLogged);
	console.log(isAppLogged);

	return (
		<>
			<div className="d-flex justify-content-between mb-md-3 mb-2 align-content-center">
				<h3 className="text-white align-self-center my-auto">Course Request</h3>

				<div className="d-flex p-1">
					<button
						onClick={() => {
							Confirm.show(
								"Confirm auto registration start",
								"Click OK to give us permission to register the selected courses for your roll number. Click cancel to stop",
								"OK",
								"Cancel",
								() => {
									// alert("Thank you.");
									registerCourseRequest({});
									Notify.info("Auto Course Registration Started", {
										position: "center-bottom",
									});
								},
								() => {
									return;
								},
								{}
							);
						}}
						className="btn btn-success me-2"
					>
						Start
					</button>

					<button
						onClick={() => stopCourseRegistration()}
						className="btn btn-danger"
					>
						Stop
					</button>
				</div>
			</div>
			<Selectedcourselist />
		</>
	);
}
