import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import Loader from "react-js-loader";
import "./Courselist.scss";

import {
	loginServerRequest,
	processCourseListwithSession,
	readSelectedCourseValuesMultiselect,
	registerCourseRequest,
	toTitleCase,
	processCourseSelectionwithSession,
	getIsCourseSelected,
} from "../../functions/course";

import { univLogin, refreshSelectedcourselist } from "../../actions";

import Selectedcourselist from "../Selectedcourselist/Selectedcourselist";
import jquery from "jquery";

export default function Courselist() {
	const dispatch = useDispatch();
	const isAppLogged = useSelector((state) => state.isAppLogged);
	// const selectedCoursesListRedux = useSelector(
	// 	(state) => state.selectedCourselistReducer
	// );
	const [slotnow, setSlotnow] = useState("E1");

	const data = {};
	// const slots = ["E1", "E2", "E3", "E4", "E5", "E6", "E7", "E8"];
	const multiselectcourseRef = React.createRef();

	const [slots, setSlots] = useState(
		localStorage.getItem("slotWiseCourseList")
			? Object.keys(JSON.parse(localStorage.getItem("slotWiseCourseList")))
			: ["E1", "E2", "E3", "E4", "E5", "E6", "E7", "E8", "E9", "E10"]
	);

	const [electiveCoursesList, setElectiveCoursesList] = useState(
		localStorage.getItem("electiveCoursesList")
			? JSON.parse(localStorage.getItem("electiveCoursesList"))
			: []
	);

	const [registeredCoursesList, setRegisteredCoursesList] = useState(
		localStorage.getItem("registeredCoursesList")
			? JSON.parse(localStorage.getItem("registeredCoursesList"))
			: []
	);

	const [slotwiseCoursesList, setslotwiseCoursesList] = useState(
		localStorage.getItem("slotWiseCourseList")
			? JSON.parse(localStorage.getItem("slotWiseCourseList"))
			: {}
	);

	// console.log("slotnow", slotnow);

	function courseClickHandlerCallback(list) {
		dispatch(refreshSelectedcourselist(list));
	}

	function courseClickHandler(e) {
		// console.log(e.target)

		let target = e.target;
		if (!target.classList.contains("course-code-name-wrapper")) {
			target = jquery(target).parents(".course-code-name-wrapper")[0];
			// console.log(target)
		}

		const isChecked = jquery(target).children("input[type=checkbox]")[0]
			.checked;
		if (isChecked) {
			jquery(target).addClass("selected-course");
			processCourseSelectionwithSession(
				JSON.parse(target.dataset.course),
				slotnow,
				"add",
				courseClickHandlerCallback
			);
		} else {
			jquery(target).removeClass("selected-course");
			processCourseSelectionwithSession(
				JSON.parse(target.dataset.course),
				slotnow,
				"remove",
				courseClickHandlerCallback
			);
		}
	}

	function handleSlotChangeInRadio(e) {
		// console.log(e)
		const target = e.target;
		const value = e.target.defaultValue;
		setSlotnow(value);

		jquery(jquery(target).parent()[0]).children().removeClass("selected-slot");
		jquery(jquery(target).next()[0]).addClass("selected-slot");
	}

	function resetSelectedCourseValues() {
		// By calling the below method will reset the selected values programatically
		multiselectcourseRef.current.resetSelectedValues();
	}

	const handleCourseListProcessing = (response) => {
		// console.log("handleCourseListProcessing", response);
		const _electiveCoursesList =
			response?.data?.courseList?.electiveCoursesList || [];

		const _slotWiseCourseList = {};

		for (let i = 0; i < _electiveCoursesList.length; i++) {
			const course = _electiveCoursesList[i];
			const courseGroupLabel = course.courseGroupLabel;

			course.displayValue =
				course.courseCode.substring(0, course.courseCode.indexOf("Cr")) +
				" " +
				toTitleCase(course.courseName);

			try {
				if (courseGroupLabel) {
					if (courseGroupLabel.includes("1"))
						!_slotWiseCourseList["E1"]
							? (_slotWiseCourseList["E1"] = [course])
							: _slotWiseCourseList["E1"].push(course);
					else if (courseGroupLabel.includes("2"))
						!_slotWiseCourseList["E2"]
							? (_slotWiseCourseList["E2"] = [course])
							: _slotWiseCourseList["E2"].push(course);
					else if (courseGroupLabel.includes("3"))
						!_slotWiseCourseList["E3"]
							? (_slotWiseCourseList["E3"] = [course])
							: _slotWiseCourseList["E3"].push(course);
					else if (courseGroupLabel.includes("4"))
						!_slotWiseCourseList["E4"]
							? (_slotWiseCourseList["E4"] = [course])
							: _slotWiseCourseList["E4"].push(course);
					else if (courseGroupLabel.includes("5"))
						!_slotWiseCourseList["E5"]
							? (_slotWiseCourseList["E5"] = [course])
							: _slotWiseCourseList["E5"].push(course);
					else if (courseGroupLabel.includes("6"))
						!_slotWiseCourseList["E6"]
							? (_slotWiseCourseList["E6"] = [course])
							: _slotWiseCourseList["E6"].push(course);
					else if (courseGroupLabel.includes("7"))
						!_slotWiseCourseList["E7"]
							? (_slotWiseCourseList["E7"] = [course])
							: _slotWiseCourseList["E7"].push(course);
					else if (courseGroupLabel.includes("8"))
						!_slotWiseCourseList["E8"]
							? (_slotWiseCourseList["E8"] = [course])
							: _slotWiseCourseList["E8"].push(course);
					else if (courseGroupLabel.includes("9"))
						!_slotWiseCourseList["E9"]
							? (_slotWiseCourseList["E9"] = [course])
							: _slotWiseCourseList["E9"].push(course);
					else if (courseGroupLabel.includes("10"))
						!_slotWiseCourseList["E10"]
							? (_slotWiseCourseList["E10"] = [course])
							: _slotWiseCourseList["E10"].push(course);
				}
			} catch (error) {
				console.log(error);
			}
		}

		setslotwiseCoursesList(_slotWiseCourseList);
		setSlots(Object.keys(_slotWiseCourseList));

		localStorage.setItem(
			"slotWiseCourseList",
			JSON.stringify(slotwiseCoursesList)
		);

		// console.log(slotwiseCoursesList);

		if (response?.data?.headers) {
			localStorage.setItem("headers", JSON.stringify(response.data.headers));
		}

		if (response?.data?.courseList?.coreCoursesList) {
			localStorage.setItem(
				"registeredCoursesList",
				JSON.stringify(response?.data?.courseList?.coreCoursesList)
			);
			setRegisteredCoursesList(response?.data?.courseList?.coreCoursesList);
		}

		if (response?.data?.courseList?.electiveCoursesList) {
			localStorage.setItem(
				"electiveCoursesList",
				JSON.stringify(response?.data?.courseList?.electiveCoursesList)
			);
			setElectiveCoursesList(response?.data?.courseList?.electiveCoursesList);
		}

		dispatch(univLogin());
	};

	if (!isAppLogged) {
		loginServerRequest(data, (response) => {
			if (response.data.courseList) {
				handleCourseListProcessing(response);
			} else {
				// alert("Error : while fetching course list");
				console.log("Error : while fetching course list");
			}
			return;
		});
	}

	if (isAppLogged) {
		return (
			<div>
				<div className="row justify-space-between">
					<div className="row container-fluid col-lg-6 my-0 px-0 pb-4 mx-auto h-75">
						<div className="d-flex px-lg-3 px-2 justify-content-between bg-primary py-2 fw-bold">
							<fieldset
								className="slot-choices-fieldset fw-bold m-0"
								onChange={handleSlotChangeInRadio}
							>
								{slots &&
									slots.map((slot) => {
										let slotlabelclassstring =
											"px-2 py-0 bg-white border rounded-3 ";
										if (slot === slotnow) {
											slotlabelclassstring += "selected-slot";
										}

										return (
											<span key={slot + "-slot"}>
												<input
													type="radio"
													name="slotnow"
													id={"slot" + slot}
													value={slot}
													hidden
												/>
												<label
													className={slotlabelclassstring}
													htmlFor={"slot" + slot}
												>
													{slot}
												</label>
											</span>
										);
									})}
							</fieldset>
						</div>

						<div className="slotchoices py-3 bg-white">
							{slotnow ? (
								slotwiseCoursesList[slotnow] &&
								slotwiseCoursesList[slotnow].map((obj, index) => {
									const isCourseSelected =
										getIsCourseSelected(obj, slotnow) || false;
									const isCourseSelectedClassName = isCourseSelected
										? "selected-course"
										: "";
									// console.log(obj, index);

									return (
										<div key={obj.courseId} className="row mx-auto">
											<div
												title={obj.courseName}
												className={
													"d-flex justify-content-between border mb-lg-2 mb-1 px-lg-2 px-1 py-0 fw-bold course-code-name-wrapper " +
													isCourseSelectedClassName
												}
												htmlFor={obj.courseId}
												data-slot={slotnow}
												data-courseid={obj.courseId}
												data-course={JSON.stringify(obj)}
												onClick={courseClickHandler}
											>
												<input
													type="checkbox"
													className="my-auto"
													name={obj.courseId + "checkbox"}
													id={obj.courseId + "checkbox"}
													defaultChecked={isCourseSelected}
													hidden
												/>

												<label
													className="d-flex justify-content-between w-100 h-100"
													htmlFor={obj.courseId + "checkbox"}
												>
													<p className="my-auto me-auto ms-1">
														{obj.courseCode.substring(
															0,
															obj.courseCode.indexOf(" Cr")
														)}
													</p>

													<p className="my-auto ms-auto text-end">
														{toTitleCase(obj.courseName)}
													</p>
												</label>
											</div>
										</div>
									);
								})
							) : (
								<h3>Please select a slot from above</h3>
							)}
						</div>
					</div>

					<div className="row container-fluid col-lg-5 my-0 px-0 pb-4 mx-auto h-75">
						<div
							className="row px-0 align-items-center justify-content-between m-0 p-0 gx-2"
							hidden
						>
							<div className="multiselectwrapper col-lg-3">
								<Multiselect
									isObject={false}
									onKeyPressFn={function noRefCheck() {}}
									onRemove={function noRefCheck() {}}
									onSearch={function noRefCheck() {}}
									onSelect={function noRefCheck(selectedList, selectedItem) {
										resetSelectedCourseValues();
										setSlotnow(selectedItem);
										console.log(selectedList, selectedItem);
									}}
									options={slots}
									singleSelect={true}
									placeholder={"Select slot to see courses"}
									className="slotDropdown w-100"
									selectedValues={[slotnow]}
									hidden
								/>
							</div>

							<div className="multiselectwrapper col-lg-9">
								<Multiselect
									showCheckbox
									avoidHighlightFirstOption
									displayValue="displayValue"
									isObject={true}
									onKeyPressFn={function noRefCheck() {}}
									onRemove={function noRefCheck(selectedList, selectedItem) {
										processCourseListwithSession(selectedList, slotnow);

										// console.log(selectedList, selectedItem);
									}}
									onSearch={function noRefCheck() {}}
									onSelect={function noRefCheck(selectedList, selectedItem) {
										processCourseListwithSession(selectedList, slotnow);
									}}
									options={slotwiseCoursesList[slotnow] || []}
									placeholder={`Select courses (${slotnow})`}
									className="slotDropdown w-100"
									id="courseMultiselectlist"
									ref={multiselectcourseRef}
									selectedValues={readSelectedCourseValuesMultiselect(slotnow)}
									style={{
										chips: {
											display: "none",
										},
									}}
									hidden
								/>
							</div>
						</div>

						<div className="d-flex m-0 p-0">
							<button
								id="flush-collapseRegisteredCoursesId"
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#flush-collapseRegisteredCourses"
								aria-expanded="true"
								aria-controls="flush-collapseRegisteredCourses"
							>
								Registered
							</button>

							<button
								id="flush-collapseSelectedCoursesId"
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#flush-collapseSelectedCourses"
								aria-expanded="false"
								aria-controls="flush-collapseSelectedCourses"
							>
								Selected
							</button>
						</div>

						<div
							className="accordion accordion-flush m-0 p-0"
							id="accordionFlushExample"
						>
							<div className="accordion-item m-0">
								<div
									id="flush-collapseRegisteredCourses"
									className="accordion-collapse collapse show"
									aria-labelledby="flush-headingOne"
									data-bs-parent="#accordionFlushExample"
								>
									<div className="accordion-body">
										<div className="mx-auto my-2 px-lg-5 px-1">
											{registeredCoursesList.map((obj) => {
												return (
													<div key={obj.courseId} className="row mb-lg-2 mb-1">
														<input
															id={obj.courseId}
															value={
																obj.courseCode + " " + obj.courseGroupLabel
															}
															className="col-lg-3 col-3 px-0 bg-success fw-bold rounded text-center text-white"
															disabled
														/>

														<input
															id={obj.courseName}
															value={toTitleCase(obj.courseName)}
															className="col bg-white text-black ms-lg-3 ms-2 rounded border border-primary"
															disabled
														/>
													</div>
												);
											})}
										</div>
									</div>
								</div>
							</div>

							<div className="accordion-item">
								<div
									id="flush-collapseSelectedCourses"
									className="accordion-collapse collapse"
									aria-labelledby="flush-headingTwo"
									data-bs-parent="#accordionFlushExample"
								>
									<div className="accordion-body">
										<button
											onClick={() => registerCourseRequest({})}
											className="btn btn-success mt-2 mb-4"
										>
											Start Registering Courses
										</button>

										<Selectedcourselist />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div>
			<div className={"row mt-3"}>
				<div className={"item"}>
					<Loader
						type="box-rectangular"
						bgColor={"#FFFFFF"}
						title={"Please wait while we are fetching your course list"}
						color={"#FFFFFF"}
						size={100}
						className="align-self-center mt-5"
					/>
				</div>
			</div>
		</div>
	);
}
