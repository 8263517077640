const univLoggedReducer = (state = 0, action) => {
    switch (action.type) {
        case 'UNIVLOGIN':
            return 1;  
        case 'UNIVLOGOUT':
            return 0;
        default:
            return state
    }
}


export default univLoggedReducer;