const switchSectionReducer = (state = 'PROFILESECTION', action) => {
    switch (action.type) {
        case 'HOMESECTION':
            return 'HOMESECTION';  
        case 'PROFILESECTION':
            return 'PROFILESECTION';  
        case 'COURSELISTSECTION':
            return 'COURSELISTSECTION';
        case 'COURSEREQUESTSECTION':
            return 'COURSEREQUESTSECTION';
        default:
            return state;
    }
}


export default switchSectionReducer;