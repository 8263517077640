import { combineReducers } from "redux";

import loggedReducer from "./isLogged";
import univLoggedReducer from "./isUnivLogged";
import switchSectionReducer from "./switchSection";
import selectedcourseReducer, {
	selectedCourselistReducer,
	snackbarState,
} from "./refreshSelectedcourse";

const reducers = combineReducers({
	isLogged: loggedReducer,
	isAppLogged: univLoggedReducer,
	currentSectionOnHomepage: switchSectionReducer,
	refreshSelectedcourse: selectedcourseReducer,
	selectedCourselistReducer: selectedCourselistReducer,
	snackbarReducer: snackbarState,
});

export default reducers;
