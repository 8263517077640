import Login from '../../components/Login/Login';
import './Loginpage.scss'


const Loginpage = () => {
    return (
        <div className='LoginPage'>
            <Login/>
        </div>
    );
}

export default Loginpage;