import Home from "../../components/Home/Home";
import Profile from "../../components/Profile/Profile";
import Courselist from "../../components/Courselist/Courselist";
import Courserequest from "../../components/Courserequest/Courserequest";

import "./Homepage.scss";

import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

const Homepage = () => {
	const isLogged = useSelector((state) => state.isLogged);
	const currentSectionOnHomepage = useSelector(
		(state) => state.currentSectionOnHomepage
	);

	if (!isLogged) {
		return <Navigate to="/login" />;
	}

	if (currentSectionOnHomepage === "HOMESECTION") {
		return (
			<div className="HomePage">
				<Home />
			</div>
		);
	}

	if (currentSectionOnHomepage === "PROFILESECTION") {
		return (
			<div className="HomePage">
				<Profile />
			</div>
		);
	} else if (currentSectionOnHomepage === "COURSELISTSECTION") {
		return (
			<div className="HomePage">
				<Courselist />
			</div>
		);
	} else if (currentSectionOnHomepage === "COURSEREQUESTSECTION") {
		return (
			<div className="HomePage">
				<Courserequest />
			</div>
		);
	}

	return (
		<>
			<h1 className="text-white">Nothing to show</h1>
		</>
	);
};

export default Homepage;
