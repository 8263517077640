import jquery from "jquery";
import React, { useState } from "react";
import Loader from "react-js-loader";

import { useDispatch, useSelector } from "react-redux";
import { refreshSelectedcourselist } from "../../actions";
import {
	processCourseSelectionwithSession,
	registerCourseRequest,
	toTitleCase,
} from "../../functions/course";

import "./Selectedcourselist.scss";

export default function Selectedcourselist(props) {
	// const [courseList, setCourseList] = useState([]);

	const courseList = useSelector((state) => state.selectedCourselistReducer);
	const dispatch = useDispatch();

	const [slots, setSlots] = useState(
		localStorage.getItem("slotWiseCourseList")
			? Object.keys(JSON.parse(localStorage.getItem("slotWiseCourseList")))
			: ["E1", "E2", "E3", "E4", "E5", "E6", "E7", "E8", "E9", "E10"]
	);

	const processDeleteCourseSelectionBtnClick = (e) => {
		const target = e.target;
		const courseDiv = jquery(target).parents(".courseWrapper")[0];
		// console.log(target, courseDiv)

		const course = JSON.parse(courseDiv?.dataset?.course) || null;
		const slotnow = courseDiv.dataset.slot;
		// console.log(course)

		if (course && slotnow)
			processCourseSelectionwithSession(course, slotnow, "remove", (list) => {
				dispatch(refreshSelectedcourselist(list));
			});
	};

	function getRndInteger(min, max) {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	return (
		<div className="px-lg-3 px-1 selectedCourseListWrapper">
			{courseList &&
				slots.map((slot) => {
					if (courseList[slot] && courseList[slot].length)
						return (
							<div
								className="px-lg-4 px-0 pt-2 slotWrapper border border-primary border-2"
								key={slot}
							>
								{courseList[slot] &&
									courseList[slot].map((course) => {
										const courseCode = course.courseCode,
											courseName = course.courseName;

										return (
											<div
												key={
													courseCode.substr(0, courseCode.indexOf("Cr") - 1) +
													slot +
													"-" +
													"selectedCourse" +
													getRndInteger(10000, 5000000)
												}
												data-slot={slot}
												data-course={JSON.stringify(course)}
												className="row mb-lg-2 mb-1 courseWrapper px-1 mx-1 gx-5"
											>
												<input
													type="text"
													value={slot}
													className="col-md-1 col-1 bg-white px-0 fw-bold border rounded rounded-info text-center text-black"
													disabled
												/>

												<input
													id={
														"courseCode" +
														"-" +
														courseCode.substr(0, courseCode.indexOf("Cr"))
													}
													value={courseCode.substr(0, courseCode.indexOf("Cr"))}
													className="col-md-2 col-2 bg-info px-0 fw-bold rounded text-center text-white ms-lg-2 ms-1"
													disabled
												/>

												<input
													id={
														"courseName" +
														"-" +
														courseName.substr(0, courseName.indexOf(" Cr"))
													}
													value={toTitleCase(courseName)}
													className="col px-1 bg-white text-black ms-lg-2 ms-1 rounded border border-primary"
													disabled
												/>

												<button
													className="col-md-1 col-1 p-0 drop-course-selection bg-danger text-white fw-bold ms-lg-2 ms-1 rounded border border-danger"
													onClick={processDeleteCourseSelectionBtnClick}
												>
													<i
														className="fa fa-window-close"
														aria-hidden="true"
													></i>
												</button>
											</div>
										);
									})}
							</div>
						);
					else if (courseList[slot] && courseList[slot].length === 0)
						return <p key={slot}>No Course Selected !!!</p>;
				})}
		</div>
	);
}
